@media (max-width: 768px) {
  .Image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Image img {
    width: 90%;
    height: 90%;
    border: 3px solid black;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
}

/* styles_tablet.css */
@media (min-width: 768px) and (max-width: 1024px) {
  /*****************
   Common styles
   ****************/

  .section {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 2%;
    margin: 1%;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .heading {
    border-radius: 30px;
    padding: 0 2%;
    max-width: 50%;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .dots-container {
    display: flex;
    justify-content: space-between;
    max-width: 60px;
    margin-left: 10px;
  }

  .dot {
    width: 20px;
    height: 18px;
    border-radius: 50%;
    margin: 2px;
    margin-top: 3px;
  }

  .red {
    background-color: rgb(255, 86, 86);
  }

  .yellow {
    background-color: rgb(255, 255, 152);
  }

  .green {
    background-color: rgb(81, 169, 81);
  }

  hr {
    width: 100%;
    border: 2px solid black;

    margin-bottom: 0%;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 99%;
  }

  .text {
    font-size: 1.6em;
    font-weight: bold;
    margin-right: 20px;
    padding-top: 0%;
  }

  /*****************
   About page styles
   ****************/

  .about {
    background-color: #e6e4e3;
  }

  .about-image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 3% auto;
    margin-top: 1%;
  }

  .about-image {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border: 6px solid #f7bf3e;
    width: 30%;
  }

  .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5%;
  }

  .about-text p,
  b {
    font-size: 220%;
    margin: 0;
  }

  .about-text hr {
    border-top: 3px dotted #000;
    border-bottom: none;
    width: 100%;
  }

  .about-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .timeline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  .timeline-image {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 0;
  }

  .timeline-item {
    margin: 15px;
  }

  /*****************
  About box styles
   *****************/
  .box {
    display: flex;
    flex-direction: column;
    border: 4px solid black;
    padding: 10px;
    width: 300px;
    height: 300px;
    margin: 5px;
  }

  .box h3 {
    font-size: 160%;
    margin-top: 1%;
    margin: 0;
  }

  .box p {
    font-size: 116%;
    line-height: 140%;
    margin: 0;
    padding-top: 10px;
  }

  .box-icon {
    margin-left: 80%;
  }

  .box-image {
    width: 90%;
    height: 90%;
    align-self: center;
  }

  .box a {
    text-decoration: dotted underline;
    font-size: 18px;
    color: rgb(0, 0, 198);
    padding: 2% 0;
  }

  .box a:hover {
    text-decoration: dashed underline;
    color: rgb(7, 7, 108);
    font-weight: bold;
  }

  /*****************
   Navbar styles
   ****************/

  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1% 1%;
    max-width: 100%;
    border-radius: 30px;
    background-color: #ecdee6;
    border: 4px solid black;
  }

  .navbar a {
    text-decoration: none;
    color: black;
  }

  .navbar a,
  .navbar span {
    border: 3px solid transparent;
    padding: 1% 2%;
    margin: 2%;
    border-radius: 14px;
  }

  .navbar span {
    background-color: #090909;
    color: #f7f4e3;
    cursor: pointer;
  }

  .navbar-left img {
    max-width: 100px;
  }

  .navbar-right {
    font-size: 150%;
    text-wrap: nowrap;
    width: 75%;
  }

  .navbar-right a,
  .navbar span {
    margin: 1%;
    cursor: pointer;
  }

  .navbar-right a:hover {
    color: black;
    border: 2px solid black;
    background-color: #ffff;
    box-shadow: 6px 6px 1px #000;
  }

  .navbar span:hover {
    color: black;
    background-color: #ffff;
    border: 3px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  /*****************
   Home styles
   ****************/

  .Header {
    text-align: center;
    background-color: #edeae5;
  }

  .Header-profile {
    margin: 3%;
    font-size: 100%;
    border-radius: 30px;
    background-color: #ecc76f;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .Home-bio-text,
  .Home-bio-heading {
    border-radius: 30px;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .Home-bio-heading {
    font-size: 150%;
    font-weight: bold;
    margin-bottom: 2%;
    text-align: center;
  }

  .Home-bio-text {
    padding: 4% 3%;
    text-align: left;
  }

  .Home-bio {
    padding: 3%;
  }

  .Home-bio p {
    font-size: 130%;
    line-height: 140%;
  }

  .profile-image {
    border-radius: 50%;
    height: 200px;
  }

  .Home-button {
    position: relative;
    border: 2px solid black;
    border-radius: 20px;
    padding: 10px;
    width: 190px;
    background-color: transparent;
    font-weight: 700;
    font-size: 140%;
    font-family: "Courier Prime", monospace;
  }

  .button-container {
    position: relative;
  }

  .Home-button:hover {
    color: #f7f4e3;
    background-color: black;
    cursor: pointer;
  }

  .arrow {
    position: absolute;
    bottom: -5px;
    right: 1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 12px 0;
    border-color: #090909 transparent transparent transparent;
    transform: rotate(135deg);
  }

  /*****************
   TechStack styles
   ****************/

  .techStack {
    text-align: center;
    background-color: #b7cfff;
  }

  .techStack p {
    font-size: 160%;
  }

  .icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    margin: 35px auto;
    padding: 6% 0;
  }

  .icon {
    margin: 0 40px;
    padding: 1%;
    margin-bottom: 50px;
    width: 13%;
    border: 3px solid transparent;
    border-radius: 30px;
    background-color: #ffff;
    border: 2px solid black;
  }

  .icon:hover {
    border: 2px solid black;
    box-shadow: 4px 4px 1px #000;
  }

  .icon-image {
    width: 60px;
    height: 60px;
  }

  .icon-text {
    margin-top: 6px;
    font-size: 22px;
  }

  /*****************
   Work styles
   ****************/

  .work {
    background-color: #edeae5;
  }

  .work a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: black;
  }

  .projects-container {
    margin: 10% 0;
  }

  .project {
    width: 90%;
    height: 40%;
    text-align: center;
    border-radius: 20px;
    border: 3px solid black;
    margin: 2%;
    border-radius: 30px;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .project h3 {
    margin: 2% 1%;
    font-size: 150%;
  }

  .project p {
    width: 90%;
    margin: 3% 4%;
    font-size: 140%;
  }
  .project img {
    max-width: 90%;
    height: 10%;
    border: 3px dashed black;
  }

  .project:hover {
    border-radius: 0px;
  }

  .work-badge {
    margin: 2% 4%;
    border-radius: 20px;
    width: 150px;
    align-self: end;
  }

  /*****************
   Footer styles
   ****************/

  .Footer {
    display: flex;
    flex-direction: column;
    background-color: #c4faeee6;
  }

  .Footer p {
    font-size: 180%;
    text-align: center;
  }

  .Footer-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .Footer-contact a {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: black;
    border: 3px solid transparent;
  }

  .Footer-contact p {
    margin: 4% 15%;
    font-size: 160%;
  }

  .Footer-contact a:hover {
    border: 3px solid black;
  }
  .Footer-contact div {
    margin: 2% 5%;
  }

  .Footer-declaration {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /*****************
   Project header styles
   ****************/

  .ProjectHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .ProjectHeader-container {
    border-radius: 30px;
    background-color: #fff;
    padding: 2%;
    margin: 1%;
  }

  .ProjectHeader h1 {
    margin-top: 0;
    margin-bottom: 5%;
    font-size: 220%;
  }

  .ProjectHeader p {
    margin: 4% 0;
    font-size: 170%;
  }

  .ProjectHeader-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .ProjectHeader-info div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4%;
    width: 100%;
    font-size: 150%;
  }

  .ProjectHeader iframe {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    max-width: 110%;
  }

  .ProjectHeader-info h4 {
    border: 3px solid black;
    border-radius: 20px;
    width: fit-content;
    padding: 0 3%;
    margin: 0;
  }

  .ProjectHeader-info p {
    font-size: 90%;
  }
  .ProjectHeader-badge {
    max-width: 40%;
  }
  /*****************
   Links styles
   ****************/
  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6% 1%;
  }

  .link {
    margin: 15px;
    border: 3px solid transparent;
  }

  .link a {
    text-decoration: underline;
    color: black;
  }
  .links img {
    width: 50px;
    height: 50px;
  }

  .link:hover {
    border: 3px solid black;
  }

  .link a:active {
    color: rgb(2, 67, 2);
  }

  /*****************
   Development Process styles
   ****************/
  .Development-process {
    background-color: #fef9c7;
  }

  /*****************
  Key-challenges styles
   ****************/

  .Key-challenges {
    background-color: #026670;
  }

  /*****************
Learnings styles
   ****************/
  .Learnings {
    background-color: #edeae5;
  }

  /*****************
   Image styles
   ****************/
  .Image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Image img {
    width: 90%;
    height: 90%;
    border: 3px solid black;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }

  /*****************
   DevFlow styles
   ****************/
  .DevFlow div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .DevFlow h3 {
    font-size: 180%;
    margin: 0%;
    padding: 0;
    border-radius: 20px;
  }

  .DevFlow ol {
    font-size: 140%;
    width: 70%;
    background-color: whitesmoke;
    border-radius: 20px;
    border: 2px solid black;
  }

  .DevFlow div {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
  }

  /*****************
   Project page styles
   ****************/
  .ProjectPage h2 {
    font-size: 150%;
    background-color: black;
    color: #f7f4e3;
    border-radius: 20px;
    padding: 2%;
    width: fit-content;
    margin-top: 10%;
  }

  .ProjectPage h3 {
    text-align: center;
    margin-top: 5%;
    background-color: #f7cf73;
    padding: 1%;
    width: 50%;
  }

  .ProjectPage ul {
    display: flex;
    flex-direction: row;
    font-size: 140%;
    padding: 2%;
  }

  .ProjectPage-list h4 {
    text-align: center;
    background-color: #f7bf3e;
    padding: 2%;
    border-radius: 20px;
    font-size: 90%;
  }

  .ProjectPage-list p {
    font-size: 70%;
    line-height: 150%;
  }

  .ProjectPage-list li {
    list-style: none;
    background-color: whitesmoke;
    margin: 0 1%;
    width: 50%;
    border-radius: 20px;
    padding: 2%;
    border: 2px solid black;
  }
  .ProjectPage-note ul {
    padding: 0;
    margin: 0;
  }

  .ProjectPage-note b {
    font-size: 140%;
    font-weight: 400;
  }

  .ProjectPage-note p {
    font-size: 90%;
    line-height: 150%;
    font-style: italic;
  }

  .ProjectPage-note li {
    list-style: none;
  }
}

@media (max-width: 768px) {
  .techStack {
    text-align: center;
    background-color: #b7cfff;
  }

  .icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 600px;
    margin: 35px auto;
  }

  .icon {
    text-align: center;
    margin-bottom: 20px;
    width: 30%;
    border-radius: 30px;
    background-color: #ffff;
    border: 2px solid black;
  }

  .icon:hover {
    border: 2px solid black;
    box-shadow: 4px 4px 1px #000;
  }

  .icon-image {
    width: 60px;
    height: 60px;
  }

  .icon-text {
    word-wrap: break-word;
    margin-top: 10px;
    font-size: 14px;
    padding-bottom: 2%;
  }
}

@media (max-width: 768px) {
  .box {
    display: flex;
    flex-direction: column;
    border: 4px solid black;
    padding: 10px;
    width: 300px;
    height: max-content;
    margin: 5px;
  }

  .box h3 {
    font-size: 140%;
    margin-top: 1%;
    margin: 0;
  }

  .box p {
    font-size: 106%;
    line-height: 140%;
    margin: 0;
    padding-top: 10px;
  }

  .box-icon {
    margin-left: 65%;
    width: 90px;
    height: 80px;
  }

  .box-image {
    width: 90%;
    height: 130%;
    align-self: center;
  }

  .box a {
    text-decoration: dotted underline;
    font-size: 18px;
    color: rgb(0, 0, 198);
    padding: 2% 0;
  }

  .box a:hover {
    text-decoration: dashed underline;
    color: rgb(7, 7, 108);
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 1% 1%;
    max-width: 100%;
    border-radius: 30px;
    background-color: #ecdee6;
    border: 4px solid black;
  }

  .navbar a {
    text-decoration: none;
    color: black;
    border: 3px solid transparent;
  }

  .navbar-left {
    margin: 4% 0;
    font-size: 170%;
    font-weight: 600;
  }

  .navbar-left img {
    max-width: 100px;
  }
  .navbar-right {
    font-size: 120%;
    text-align: center;
  }

  .navbar-right a:hover {
    color: black;
    border: 2px solid black;
    background-color: #ffff;
    box-shadow: 6px 6px 1px #000;
  }

  .navbar-right a,
  .navbar span {
    display: inline-block;
    margin: 2%;
    border-radius: 14px;
  }

  .navbar span {
    background-color: #090909;
    color: #f7f4e3;
    cursor: pointer;
    padding: 1% 3%;
    width: max-content;
  }

  .navbar a,
  .navbar span {
    border: 3px solid transparent;
  }

  .navbar-right a:hover {
    color: black;
    border: 2px solid black;
    border-radius: 10px;
    padding: 0 1%;
  }

  .navbar span:hover {
    color: black;
    background-color: #ffff;
    border: 3px solid black;
    box-shadow: 6px 6px 1px #000;
    border-radius: 10px;
  }
}

@media (max-width: 768px) {
  .about {
    background-color: #e6e4e3;
  }

  .about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5% auto;
    padding: 20px;
  }

  .about-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3% auto;
    margin-top: 1%;
  }

  .about-image {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border: 4px solid #f7bf3e;
    width: 80%;
  }

  .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5%;
    padding: 2%;
  }

  .about-text p,
  b {
    font-size: 170%;
    margin: 0;
  }

  .about-text hr {
    border-top: 3px dotted #000;
    border-bottom: none;
    width: 100%;
  }

  .about-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .timeline {
    position: relative;
    margin-bottom: 40px;
  }

  .timeline-image {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .timeline-item {
    margin: 15px;
  }
}

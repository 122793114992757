.Blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7% 2%;
}

.Blog h2 {
  font-size: 200%;
  margin-bottom: 5%;
}

.Blog-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.Blog-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 290px;
  padding: 16px;
  border-radius: 30px;
  background-color: #edeae5;
  border: 2px solid black;
  box-shadow: 6px 6px 1px #000;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.Blog-card:hover {
  border-radius: 0%;
}

.Blog-button {
  /* styles you want to apply to the button when the card is hovered */
  background-color: #676452;
}
.Blog-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.Blog-card h3 {
  font-size: 140%;
  margin: 16px 0;
}

.Blog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Blog-overlay-content img {
  width: 100%;
}

.Blog-overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f1ec;
  width: 82vw;
  max-height: 90vh;
  overflow-y: auto;
  padding: 39px;
  border-radius: 8px;
}

.Blog-overlay-content h2 {
  font-size: 200%;
}
.Blog-overlay-content h3 {
  margin-top: 0;
  font-size: 180%;
}

.Blog-overlay-content p {
  font-size: 115%;
}

.Blog-button {
  border: 2px solid black;
  border-radius: 20px;
  padding: 5px;
  width: 130px;
  background-color: transparent;
  font-weight: 700;
  font-size: 100%;
  margin-top: 5%;
  font-family: "Courier Prime", monospace;
}

.Blog-card-bottom {
  display: flex;
  justify-content: flex-end;
}

.Blog-overlay-content a {
  text-decoration: underline;
  color: #676452;
}

.Blog-overlay-button {
  border: 3px solid black;
  text-decoration: none;
  text-align: center;
  color: #676452;
  border-radius: 20px;
  padding: 5px;
  width: 200px;
  background-color: transparent;
  font-weight: 700;
  font-size: 130%;
  margin-top: 5%;
  font-family: "Courier Prime", monospace;
}

.Blog-overlay-button:hover {
  background-color: #676452;
  color: #eee6b7;
}

.Blog-overlay-close {
  position: absolute;
  top: 40px;
  right: 130px;
  background-color: transparent;
  border: none;
  font-size: 28px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  color: #6d6951;
}

.Blog-overlay-close:hover {
  color: #e84905;
}

/* Mobile styles */
@media (max-width: 768px) {
  .Blog-overlay-close {
    position: absolute;
    top: 50px;
    right: 30px;
  }

  .Blog-overlay-content {
    width: 90vw;
    padding: 10px;
  }

  .Blog-overlay-content h2 {
    margin-top: 10%;
    font-size: 150%;
  }

  .Blog-overlay-content h3 {
    font-size: 150%;
  }

  .Blog-overlay-content p {
    font-size: 110%;
  }

  .Blog-button,
  .Blog-overlay-button {
    font-size: 100%;
    padding: 4px;
  }
}

/* styles_tablet.css */
@media (min-width: 768px) and (max-width: 1023px) {
  .Blog-overlay-close {
    position: absolute;
    top: 50px;
    right: 50px;
    font-size: 200%;
  }

  .Blog-overlay-content {
    width: 85vw;
    padding: 28px;
  }

  .Blog-overlay-content h2 {
    font-size: 200%;
  }

  .Blog-overlay-content h3 {
    font-size: 170%;
  }

  .Blog-overlay-content h4 {
    font-size: 150%;
  }

  .Blog-overlay-content p {
    font-size: 130%;
  }

  .Blog-overlay-content ol {
    font-size: 120%;
  }

  .Blog-button,
  .Blog-overlay-button {
    font-size: 110%;
    padding: 5px;
  }
}

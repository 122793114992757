.resume-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #edeae5;
}

.resume-container h2 {
  animation: pulse 1.5s infinite;
  color: #bb86a5;
  font-size: 2rem;
}

/* Animation for the text */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

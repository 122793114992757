@media (max-width: 768px) {
  .section {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 2%;
    margin: 1%;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading {
    border-radius: 20px;
    padding: 0 2%;
    max-width: 50%;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
    font-size: 70%;
  }

  .dots-container {
    display: flex;
    justify-content: space-between;
    max-width: 50px;
    margin-left: 10px;
  }

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 2px;
    margin-top: 3px;
  }

  .red {
    background-color: rgb(255, 86, 86);
  }

  .yellow {
    background-color: rgb(255, 255, 152);
  }

  .green {
    background-color: rgb(81, 169, 81);
  }

  hr {
    width: 100%;
    border: 2px solid black;
    margin-bottom: 0%;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text {
    font-size: 1.2em;
    font-weight: bold;
    margin-right: 10px;
    padding-top: 0%;
  }
}

body {
  margin: 0;
  /* font-family: "Courier Prime", monospace; */
  /* font-family: "Saira Condensed", sans-serif; */
  font-family: "Kurale", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .Footer {
    display: flex;
    flex-direction: column;
    line-height: 130%;
    text-align: center;
    justify-content: center;
    background-color: #c4faeee6;
  }

  .Footer-container {
    display: flex;
    margin: 3% 4%;
    flex-direction: column;
    justify-content: center;
  }

  .Footer-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .Footer-contact a {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: black;
    border: 3px solid transparent;
  }

  .Footer-contact p {
    margin: 4% 15%;
    font-size: 80%;
  }
  .Footer-contact a:hover {
    border: 3px solid black;
    box-shadow: 6px 6px 1px #000;
    border-radius: 16px;
    background-color: #fcfaf9;
  }
  .Footer-contact div {
    margin: 12% 5%;
  }

  .Footer-declaration {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Footer-declaration small {
    line-height: 90%;
  }

  .Footer-declaration a {
    text-decoration: wavy underline;
    color: #090909;
    font-size: 90%;
  }
}

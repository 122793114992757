@media (max-width: 768px) {
  /*****************
   Development Process styles
   ****************/
  .Development-process {
    background-color: #fef9c7;
  }

  /*****************
  Key-challenges styles
   ****************/

  .Key-challenges {
    background-color: #026670;
  }

  /*****************
Learnings styles
   ****************/
  .Development-process h1 .Learnings h1 {
    text-wrap: nowrap;
    font-size: 180%;
  }

  .Learnings {
    background-color: #edeae5;
  }

  /*****************
DevFlow styles
   ****************/
  .DevFlow div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .DevFlow h3 {
    font-size: 180%;
    margin: 0%;
    padding: 0;
    border-radius: 20px;
  }

  .DevFlow ol {
    font-size: 100%;
    line-height: 150%;
    width: 80%;
    background-color: whitesmoke;
    border-radius: 20px;
    border: 2px solid black;
  }

  .DevFlow div {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
  }
}

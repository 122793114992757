/* padding: 4% 0; */
/* styles_desktop.css */
@media (min-width: 1024px) {
  /*****************
   Common styles
   ****************/

  .section {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 2%;
    margin: 1%;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading {
    border-radius: 30px;
    padding: 0 2%;
    max-width: 50%;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .dots-container {
    display: flex;
    justify-content: space-between;
    max-width: 60px;
    margin-left: 10px;
  }

  .dot {
    width: 90px;
    height: 14px;
    border-radius: 50%;
    margin: 3px;
    margin-top: 13px;
  }

  .red {
    background-color: rgb(255, 86, 86);
  }

  .yellow {
    background-color: rgb(255, 255, 152);
  }

  .green {
    background-color: rgb(81, 169, 81);
  }

  hr {
    max-width: 99%;
    border: 2px solid black;
    margin-bottom: 0%;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 97%;
  }

  .text {
    font-size: 2em;
    font-weight: bold;
    margin-right: 10px;
    padding-top: 0%;
  }

  /*****************
   About page styles
   ****************/

  .about {
    background-color: #e6e4e3;
  }

  .about-page {
    margin: 2% auto;
    padding: 20px;
  }

  .about-image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 3% auto;
    margin-top: 1%;
  }

  .about-image {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border: 6px solid #f7bf3e;
    width: 20%;
  }

  .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5%;
  }

  .about-text p {
    color: #1c1607;
  }

  .about-text p,
  b {
    font-size: 220%;
    margin: 0;
  }

  .about-text hr {
    border-top: 3px dotted #000;
    border-bottom: none;
    width: 110%;
  }

  .about-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .timeline {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
    width: 100%;
  }

  .timeline-image {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }

  .timeline-item {
    margin: 15px;
  }

  /*****************
   About box styles
   ****************/

  .box {
    display: flex;
    flex-direction: column;
    border: 4px solid black;
    padding: 10px;
    width: 340px;
    height: 270px;
    margin: 5px;
  }

  .box h3 {
    font-size: 160%;
    margin-top: 1%;
    margin: 0;
  }

  .box p {
    font-size: 116%;
    line-height: 140%;
    margin: 0;
    padding-top: 10px;
  }

  .box-icon {
    margin-left: 80%;
  }

  .box-image {
    width: 80%;
    height: 140%;
    align-self: center;
  }

  .box a {
    text-decoration: dotted underline;
    font-size: 18px;
    color: rgb(0, 0, 198);
    padding: 2% 0;
  }

  .box a:hover {
    text-decoration: dashed underline;
    color: rgb(7, 7, 108);
    font-weight: bold;
  }

  /*****************
   Navbar styles
   ****************/

  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1% 1%;
    max-width: 100%;
    border-radius: 30px;
    background-color: #ecdee6;
    border: 4px solid black;
  }

  .navbar a {
    text-decoration: none;
    color: black;
  }

  .navbar a,
  .navbar span {
    border: 2px solid transparent;
    padding: 1% 2%;
    margin: 2%;
    border-radius: 14px;
  }

  .navbar span {
    background-color: #090909;
    color: #f7f4e3;
    cursor: pointer;
  }

  .navbar-left {
    align-items: left;
  }

  .navbar-left img {
    max-width: 100px;
  }

  .navbar-right {
    font-size: 150%;
    margin: 0 4%;
    text-wrap: nowrap;
    width: 50%;
  }
  .navbar-right a:hover {
    color: black;
    border: 2px solid black;
    background-color: #ffff;
    box-shadow: 6px 6px 1px #000;
  }

  .navbar span:hover {
    color: black;
    background-color: #ffff;
    border: 3px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  /*****************
   Home styles
   ****************/

  .Header {
    text-align: center;
    height: fit-content;
    background-color: #edeae5;
  }

  .Header-components {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1% 0;
    margin-bottom: 4%;
  }

  .Header-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3%;
    width: 50%;
    font-size: 130%;
    border-radius: 30px;
    background-color: #ecc76f;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }
  .Home-bio-text,
  .Home-bio-heading {
    border-radius: 30px;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .Home-bio-heading {
    font-size: 150%;
    font-weight: bold;
    margin-bottom: 5%;
    text-align: center;
  }

  .Home-bio-text {
    font-size: 120%;
    line-height: 2;
    height: 60%;
    padding: 6% 3%;
    text-align: left;
  }

  .Home-bio-text p {
    margin: 0;
  }
  .Home-bio {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 3%;
    padding: 0 3%;
    line-height: 160%;
    font-size: 130%;
    width: 50%;
  }

  .profile-image {
    width: 40%;
    border-radius: 50%;
    margin: 8%;
    border: 8px solid #f7bf3e;
  }

  .name {
    font-size: 200%;
  }

  .role {
    font-size: 200%;
  }

  .Home-button {
    position: relative;
    border: 3px solid black;
    border-radius: 20px;
    padding: 10px;
    width: 200px;
    background-color: transparent;
    font-weight: 700;
    font-size: 150%;
    font-family: "Courier Prime", monospace;
    margin-bottom: 2%;
  }

  .button-container {
    position: relative;
  }

  .Home-button:hover {
    color: #f7f4e3;
    background-color: black;
    cursor: pointer;
  }

  .arrow {
    position: absolute;
    bottom: -5px;
    left: 160px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 22px 0;
    border-color: #090909 transparent transparent transparent;
    transform: rotate(135deg);
  }

  /* Services page */
  .services-arrow {
    position: absolute;
    bottom: -35px;
    left: 80px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 22px 0;
    border-color: #090909 transparent transparent transparent;
    /* transform: rotate(135deg); */
  }

  /*****************
   TechStack styles
   ****************/
  .techStack {
    text-align: center;
    background-color: #b7cfff;
  }

  .techStack p {
    font-size: 160%;
  }

  .icons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px auto;
    padding: 5% 0;
    width: 80%;
    padding-bottom: 2%;
  }

  .icon {
    display: inline-block;
    margin: 0 50px;
    align-items: center;
    margin: 0 50px;
    padding: 1%;
    margin-bottom: 50px;
    width: 13%;
    border: 3px solid transparent;
    border-radius: 30px;
    background-color: #ffff;
    border: 2px solid black;
  }

  .icon:hover {
    border: 2px solid black;
    box-shadow: 4px 4px 1px #000;
  }

  .icon-image {
    width: 70px;
  }

  .icon-text {
    margin-top: 5px;
    font-size: 20px;
  }

  /*****************
   Work styles
   ****************/

  .work {
    background-color: #edeae5;
  }

  .work a {
    text-decoration: none;
    color: black;
    width: 50%;
  }

  .projects-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 6% 0;
  }

  .project {
    text-align: center;
    border: 3px solid black;
    margin: 2%;
    border-radius: 30px;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .project:hover {
    border-radius: 0px;
  }

  .project p {
    width: 90%;
    margin: 3% 4%;
  }
  .project img {
    max-width: 90%;
    height: 280px;
    border: 3px dashed black;
  }

  .project-description-container {
    display: flex;
    justify-content: space-around;
  }

  .work-badge {
    margin: 2% 4%;
    border-radius: 20px;
    max-height: 80px;
  }
  /*****************
   Footer styles
   ****************/

  .Footer {
    background-color: #c4faeee6;
  }

  .Footer-container {
    display: flex;
    margin: 3% 4%;
    flex-direction: column;
    justify-content: center;
  }

  .Footer p {
    font-size: 150%;
    text-align: center;
  }

  .Footer-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .Footer-contact a {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    color: black;
    width: fit-content;
    border: 3px solid transparent;
  }

  .Footer-contact p {
    margin: 4% 15%;
    font-size: 160%;
  }

  .Footer-contact a:hover {
    border: 3px solid black;
    box-shadow: 6px 6px 1px #000;
    border-radius: 16px;
    padding: 0 5%;
    background-color: #fcfaf9;
  }

  .Footer-contact div {
    margin: 2% 4%;
  }

  .Footer-declaration {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 80%;
    margin-top: 1%;
  }

  .Footer-declaration small {
    line-height: 100%;
  }

  .Footer-declaration a {
    text-decoration: dotted underline;
    color: #090909;
    font-size: 140%;
    border-radius: 16px;
    padding: 10px;
  }

  .Footer-declaration a:hover {
    text-decoration: dashed underline;
    font-weight: 600;
  }

  /*****************
   Project header styles
   ****************/

  .ProjectHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .ProjectHeader-container {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #fff;
    padding: 2%;
    margin: 1%;
  }

  .ProjectHeader-items {
    display: flex;
    flex-direction: row;
  }

  .ProjectHeader-items-text {
    max-width: 50%;
    margin: 3%;
  }

  .ProjectHeader-items-demo {
    max-width: 40%;
    margin: 0.4%;
  }

  .ProjectHeader h1 {
    margin-top: 0;
    margin-bottom: 5%;
    font-size: 210%;
  }

  .ProjectHeader p {
    margin: 4% 0;
    font-size: 160%;
    line-height: 150%;
  }

  .ProjectHeader-info {
    display: flex;
    flex-direction: row;
  }

  .ProjectHeader-info div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4%;
    width: 100%;
    font-size: 130%;
    line-height: 150%;
  }

  .ProjectHeader iframe {
    max-width: 110%;
    border-radius: 30px;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .ProjectHeader-info h4 {
    border: 3px solid black;
    border-radius: 20px;
    width: fit-content;
    padding: 0 3%;
    margin: 0;
  }

  .ProjectHeader-info p {
    font-size: 90%;
  }

  .ProjectHeader-badge {
    max-width: 40%;
  }

  /*****************
   Development Process styles
   ****************/
  .Development-process {
    background-color: #fef9c7;
  }

  /*****************
  Key-challenges styles
   ****************/

  .Key-challenges {
    background-color: #026670;
  }

  /*****************
Learnings styles
   ****************/
  .Learnings {
    background-color: #edeae5;
  }

  /*****************
   Links styles
   ****************/

  .links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 3% 0;
  }

  .link {
    margin: 15px;
    border: 3px solid transparent;
  }

  .link a {
    text-decoration: underline;
    color: black;
  }
  .links img {
    width: 50px;
    height: 50px;
  }

  .link:hover {
    border: 3px solid black;
  }

  .link p {
    font-size: 140%;
  }

  .link a:active {
    color: rgb(2, 67, 2);
  }

  /*****************
   Image styles
   ****************/

  .Image {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Image img {
    width: 80%;
    height: 90%;
    margin: 0 7%;
    border: 3px solid black;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
  }

  .Image img:hover {
    border-radius: 30px;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .small-image img {
    width: 50%;
    height: auto;
  }

  /*****************
   DevFlow styles
   ****************/

  .DevFlow div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .DevFlow h3 {
    font-size: 180%;
    margin: 0%;
    padding: 0;
    border-radius: 20px;
  }

  .DevFlow ol {
    font-size: 120%;
    line-height: 160%;
    width: 70%;
    background-color: whitesmoke;
    border-radius: 20px;
    border: 2px solid black;
  }

  .DevFlow div {
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
  }

  /*****************
   Project page styles
   ****************/

  .ProjectPage h2 {
    font-size: 150%;
    background-color: black;
    color: #f7f4e3;
    border-radius: 20px;
    padding: 2%;
    width: fit-content;
    margin-top: 10%;
  }

  .ProjectPage h3 {
    text-align: center;
    margin-top: 5%;
    background-color: #f7cf73;
    padding: 1%;
    width: 50%;
  }

  .ProjectPage ul {
    display: flex;
    flex-direction: row;
    font-size: 140%;
    padding: 2%;
  }

  .ProjectPage-list h4 {
    text-align: center;
    background-color: #f7bf3e;
    padding: 2%;
    border-radius: 20px;
  }

  .ProjectPage-list p {
    font-size: 80%;
    line-height: 160%;
    padding: 0 2%;
  }

  .ProjectPage-list li {
    list-style: none;
    background-color: whitesmoke;
    margin: 1%;
    width: 50%;
    border-radius: 20px;
    padding: 1%;
    border: 2px solid black;
  }

  .ProjectPage-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ProjectPage-note {
    display: flex;
    flex-direction: row;
    margin: 0 2%;
  }

  .ProjectPage-note ul {
    padding: 0;
    margin: 0;
  }

  .ProjectPage-note p {
    font-size: 90%;
    line-height: 150%;
    font-style: italic;
  }

  .ProjectPage-note li {
    list-style: none;
  }
}

/* Blog styles */

.Blog-container {
  background-color: #fcf6f5;
}

/* center image */
.Blog-overlay figure {
  width: 40%;
  margin: 0 20%;
  padding: 0;
}

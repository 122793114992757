@media (max-width: 768px) {
  .ProjectPage ul {
    display: flex;
    flex-direction: column;
    font-size: 140%;
    width: 80%;
  }

  .ProjectPage h3 {
    text-align: center;
    margin-top: 5%;
    background-color: #f7cf73;
    padding: 1%;
    font-size: 140%;
    width: 80%;
  }

  .ProjectPage-list h4 {
    text-align: center;
    font-size: 80%;
    background-color: #f7bf3e;
    padding: 2%;
    margin: 2%;
    border-radius: 20px;
  }

  .ProjectPage-list b {
    font-size: 140%;
  }

  .ProjectPage-list p {
    font-size: 70%;
    line-height: 150%;
    padding: 0 2%;
  }

  .ProjectPage-list li {
    list-style: none;
    background-color: whitesmoke;
    border-radius: 20px;
    padding: 1%;
    border: 2px solid black;
  }

  .ProjectPage-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ProjectPage-note {
    display: flex;
    flex-direction: column;
    padding: 0 3%;
  }

  .ProjectPage-note ul {
    padding: 0;
    margin: 0;
  }

  .ProjectPage-note p {
    font-size: 70%;
    line-height: 140%;
    font-style: italic;
  }

  .ProjectPage-note b {
    font-size: 120%;
    font-weight: 600;
  }

  .ProjectPage-note li {
    list-style: none;
  }
}

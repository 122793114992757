@media (max-width: 768px) {
  .work {
    background-color: #edeae5;
  }

  .work a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: black;
  }

  .projects-container {
    margin: 10% 0;
  }

  .project {
    width: 92%;
    height: 20%;
    min-height: 20%;
    box-sizing: border-box;
    text-align: center;
    border-radius: 30px;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
    margin: 2%;
  }

  .project:hover {
    border-radius: 0px;
  }

  .project h3 {
    margin: 2% 1%;
  }

  .project p {
    width: 90%;
    margin: 3% 4%;
  }
  .project img {
    max-width: 300px;
    max-height: 400px;
    border: 3px dashed black;
  }

  .project-description-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .work-badge {
    margin: 2% 4%;
    border-radius: 20px;
    height: 60px;
    width: 60px;
    align-self: end;
  }
}

/* App.css */
body {
  /* background-color: #f7f4e3; */
  position: relative;
}

/* .overlay {
  content: "";
  background-image: url("../images/background.png");
  background-repeat: repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.14;
  z-index: -1;
} */

/* .App {
  border: 24px solid black;
} */

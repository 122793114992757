@media (max-width: 768px) {
  .ProjectHeader {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .ProjectHeader-container {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #fff;
    padding: 2%;
    margin: 1%;
  }

  .ProjectHeader h1 {
    margin-top: auto;
  }

  .ProjectHeader p {
    margin: 8% 0;
  }

  .ProjectHeader-info {
    display: flex;
    flex-direction: row;
  }

  .ProjectHeader-info div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8% 2%;
    width: 100%;
  }

  .ProjectHeader iframe {
    width: 95%;
    height: 30vh;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }

  .ProjectHeader-info h4 {
    border: 3px solid black;
    border-radius: 20px;
    width: fit-content;
    padding: 0 3%;
    margin: 0;
  }

  .ProjectHeader-info p {
    font-size: 90%;
  }

  .ProjectHeader-badge {
    border-radius: 20px;
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .Header {
    text-align: center;
    height: fit-content;
    background-color: #edeae5;
  }

  .Header-profile {
    font-size: 90%;
    margin: 3%;
    border-radius: 30px;
    background-color: #ecc76f;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .Home-bio-text,
  .Home-bio-heading {
    border-radius: 30px;
    background-color: #fff;
    border: 2px solid black;
    box-shadow: 6px 6px 1px #000;
  }

  .Home-bio-heading {
    font-size: 150%;
    font-weight: bold;
    margin-bottom: 5%;
    text-align: center;
  }

  .Home-bio-text {
    padding: 6% 3%;
    text-align: left;
  }

  .Home-bio {
    padding: 0 3%;
    line-height: 160%;
    font-size: 120%;
    margin-bottom: 6%;
  }

  .Home-bio-img {
    display: flex;
    justify-content: flex-end;
  }

  .Home-image-container {
    text-align: center;
  }

  .profile-image {
    border-radius: 50%;
    border: 8px solid #f7bf3e;
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .name {
    text-align: center;
  }

  .role {
    text-align: center;
  }

  .Home-button {
    position: relative;
    border: 2px solid black;
    border-radius: 20px;
    padding: 10px;
    width: 170px;
    background-color: transparent;
    font-weight: 700;
    font-size: 120%;
    font-family: "Courier Prime", monospace;
  }

  .button-container {
    position: relative;
  }

  .Home-button:hover {
    color: #f7f4e3;
    background-color: black;
    cursor: pointer;
  }

  .Header {
    text-align: center;
  }

  .arrow {
    position: absolute;
    bottom: -5px;
    right: 1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 12px 0;
    border-color: #090909 transparent transparent transparent;
    transform: rotate(135deg);
  }
}
